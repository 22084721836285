import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { TaskType } from "./Task";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { ContractorViewModel } from "TypeGen/contractor-view-model";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { cloneElement } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  data: TaskType;
  onClose: () => void;
  onSubmit: (t: TaskType) => void;
  contractors: ContractorViewModel[];
};

type Inputs = Pick<
  TaskType,
  "start" | "end" | "title" | "percentComplete" | "details" | "contractorId" | "percentGoal" | "drawBudget" | "week"
>;

const TaskEditModal = ({ data, onClose, onSubmit, contractors }: Props) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
  } = useForm<Inputs>({
    defaultValues: {
      ...data,
    },
  });
  const onFormSubmit: SubmitHandler<Inputs> = (inputs) => {
    onSubmit({ ...data, ...inputs });
  };

  const getContractorName = (id: number) =>
    contractors.find((c) => c.ContractorID === id)?.FullName ?? "";

  const chat = () => {
    navigate(`/Construction/Task/${data.id}`);
  }

  return (
    <Window
      modal
      title="Edit task"
      onClose={onClose}
      initialWidth={Math.min(window.innerWidth, 1000)}
      initialHeight={800}
    >
      <div className="k-form k-form-md">
        <FieldWrapper>
          <Label>Task name</Label>
          <Controller
            name="title"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Details</Label>
          <Controller
            name="details"
            control={control}
            render={({ field }) => <TextArea {...field} />}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Contractor</Label>
          <Controller
            name="contractorId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DropDownList
                data={contractors.map((c) => c.ContractorID)}
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                itemRender={(li, itemProps) =>
                  cloneElement(
                    li,
                    li.props,
                    <span>{getContractorName(itemProps.dataItem)}</span>
                  )
                }
                valueRender={(el, value) =>
                  cloneElement(
                    el,
                    el.props,
                    <span>{getContractorName(value)}</span>
                  )
                }
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Planned start date</Label>
          <Controller
            name="start"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateTimePicker
                value={value}
                onChange={(e) => onChange(e.value)}
                valid={true}
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Planned end date</Label>
          <Controller
            name="end"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateTimePicker
                value={value}
                onChange={(e) => onChange(e.value)}
                valid={true}
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Goal Percent Complete</Label>
          <Controller
            name="percentGoal"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericTextBox
                format="p"
                value={value}
                onChange={onChange}
                step={0.01}
                min={0}
                max={1}
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Actual Percent Complete</Label>
          <Controller
            name="percentComplete"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericTextBox
                format="p"
                value={value}
                onChange={onChange}
                step={0.01}
                min={0}
                max={1}
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Draw Budget</Label>
          <Controller
            name="drawBudget"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericTextBox
                format="c2"
                value={value}
                onChange={onChange}
                step={1.00}
                min={0}
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>Week #</Label>
          <Controller
            name="week"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericTextBox
                value={value}
                onChange={onChange}
                step={1}
                min={1}
              />
            )}
          />
        </FieldWrapper>
      </div>
      <WindowActionsBar layout="end">
        {data.id && data.contractorId > 0 && <Button themeColor="secondary" onClick={chat}>Chat</Button>}
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button themeColor="primary" onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </WindowActionsBar>
    </Window>
  );
};

export default TaskEditModal;
