import { FieldWrapper } from '@progress/kendo-react-form';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ContractorViewModel } from 'TypeGen/contractor-view-model';
import LoadingPanel from 'components/LoadingPanel';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, unstable_usePrompt, useParams } from 'react-router-dom';
import { Label, Error as KendoError } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { fetchApi } from 'services/api';
import { Title } from 'utils/title';
import { EMAIL_REGEX, JsonResponse } from 'utils/generic';
import moment from 'moment';
import { Button } from '@progress/kendo-react-buttons';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import { paths } from 'App';
import { Fade } from '@progress/kendo-react-animation';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import useTimeoutState from 'utils/useTimeoutState';
import { useEffect } from 'react';

const ContractorProfile = () => {
    const { ContractorId: contractorId } = useParams();

    const queryClient = useQueryClient();

    const { data, isLoading } = useQuery({
        queryKey: ['contractors', contractorId],
        queryFn: async () => {
            const res: { contractor: ContractorViewModel; isAdmin: boolean } = await fetchApi(`/api/Contractor/Get/${contractorId}`);
            return res;
        },
    });

    const [confirmMessage, setConfirmMessage] = useTimeoutState<string>(5000);
    const [errorMessage, setErrorMessage] = useTimeoutState<string>(5000);

    useEffect(() => {
        if (confirmMessage != null) {
            setErrorMessage(undefined);
        }
        if (errorMessage != null) {
            setConfirmMessage(undefined);
        }
    }, [confirmMessage, errorMessage, setConfirmMessage, setErrorMessage]);

    const update = useMutation({
        mutationFn: async (contractor: ContractorViewModel) => {
            const res: JsonResponse = await fetchApi(`api/Contractor/Edit/${contractorId}`, contractor, 'POST');
            if (!res.Success) {
                throw new Error(res.ErrorMessage);
            }
        },
        onError(error, variables, context) {
            setErrorMessage(error.message);
        },
        onSuccess(_data, _variables, _context) {
            queryClient.invalidateQueries({ queryKey: ['contractors', contractorId] });
        },
    });

    if (isLoading || data == null) {
        return <LoadingPanel />;
    }

    const { contractor, isAdmin } = data;

    return (
        <>
            <Title string={contractor.FullName} />
            <ContractorProfileForm contractor={contractor} isAdmin={isAdmin} update={update.mutate} setConfirmMessage={setConfirmMessage} setErrorMessage={setErrorMessage} />
            <NotificationGroup
                style={{
                    left: '50%',
                    top: '3em',
                    transform: 'translate(-50%, 0)',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap-reverse',
                }}
            >
                <Fade>
                    {confirmMessage != null && (
                        <Notification
                            type={{
                                style: 'success',
                                icon: true,
                            }}
                            closable={true}
                            onClose={() => setConfirmMessage(undefined)}
                        >
                            <span>{confirmMessage}</span>
                        </Notification>
                    )}
                    {errorMessage != null && (
                        <Notification
                            type={{
                                style: 'error',
                                icon: true,
                            }}
                            closable={true}
                            onClose={() => setErrorMessage(undefined)}
                        >
                            <span>{errorMessage}</span>
                        </Notification>
                    )}
                </Fade>
            </NotificationGroup>
        </>
    );
};

const ContractorProfileForm = ({
    contractor,
    isAdmin,
    update,
    setConfirmMessage,
    setErrorMessage,
}: {
    contractor: ContractorViewModel;
    isAdmin: boolean;
    update: UseMutateFunction<void, Error, ContractorViewModel, unknown>;
    setConfirmMessage: (message?: string) => void;
    setErrorMessage: (message?: string) => void;
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors, isDirty },
        reset,
    } = useForm<ContractorViewModel>({
        defaultValues: {
            Email: contractor.Email,
            FirstName: contractor.FirstName,
            LastName: contractor.LastName,
            CompanyName: contractor.CompanyName,
            CaliforniaLicenseNumber: contractor.CaliforniaLicenseNumber,
            PhoneNumber: contractor.PhoneNumber,
            Active: contractor.Active,
        },
    });

    const onSubmit: SubmitHandler<ContractorViewModel> = async (data) => {
        const newData: ContractorViewModel = {
            ...data,
            Email: data.Email.trim(),
            FirstName: data.FirstName.trim(),
            LastName: data.LastName.trim(),
            CompanyName: data.CompanyName.trim(),
            CaliforniaLicenseNumber: data.CaliforniaLicenseNumber.trim(),
            PhoneNumber: data.PhoneNumber.trim(),
        };
        update(newData, {
            onSuccess: () => {
                setConfirmMessage('Changes saved');
                reset({
                    Email: newData.Email,
                    FirstName: newData.FirstName,
                    LastName: newData.LastName,
                    CompanyName: newData.CompanyName,
                    CaliforniaLicenseNumber: newData.CaliforniaLicenseNumber,
                    PhoneNumber: newData.PhoneNumber,
                    Active: newData.Active,
                });
            },
        });
    };

    const toggleRetire = () => {
        setConfirmMessage(contractor.Active ? 'Contractor successfuly retired' : 'Contractor successfuly un-retired');
        const toggledContractor: ContractorViewModel = { ...contractor, Active: !contractor.Active };
        update(toggledContractor);
    };

    const retireButton = !contractor.Active ? (
        <Button type="button" onClick={() => window.confirm('Un-retire contractor?') && toggleRetire()}>
            Un-Retire Contractor
        </Button>
    ) : (
        <Button type="button" onClick={() => window.confirm('Retire contractor?') && toggleRetire()}>
            Retire Contractor
        </Button>
    );

    const clearMessages = () => {
        setConfirmMessage(undefined);
        setErrorMessage(undefined);
    };

    unstable_usePrompt({
        message: 'Form changes are unsaved!',
        when: isDirty,
    });

    return (
        <div className="k-form k-form-md container">
            <Link to={paths.Contractor}>
                <Button fillMode="link" icon={chevronLeftIcon.name} type="button">
                    Back to contractors
                </Button>
            </Link>
            <form onSubmit={handleSubmit(onSubmit)} onChange={clearMessages}>
                <div className="row">
                    <div className="col-md-6">
                        <Controller
                            name="FirstName"
                            control={control}
                            rules={{ required: 'First name cannot be empty.', maxLength: 25 }}
                            render={({ field }) => (
                                <FieldWrapper>
                                    <Label>First Name</Label>
                                    <Input {...field} />
                                    <KendoError>{errors.FirstName?.message}</KendoError>
                                </FieldWrapper>
                            )}
                        />
                    </div>
                    <div className="col-md-6">
                        <Controller
                            name="LastName"
                            control={control}
                            rules={{ required: 'Last name cannot be empty.', maxLength: 25 }}
                            render={({ field }) => (
                                <FieldWrapper>
                                    <Label>Last Name</Label>
                                    <Input {...field} />
                                    <KendoError>{errors.LastName?.message}</KendoError>
                                </FieldWrapper>
                            )}
                        />
                    </div>
                </div>
                <Controller
                    name="CompanyName"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                        <FieldWrapper>
                            <Label>Company Name</Label>
                            <Input {...field} />
                            <KendoError>{errors.CompanyName?.message}</KendoError>
                        </FieldWrapper>
                    )}
                />
                <Controller
                    name="CaliforniaLicenseNumber"
                    control={control}
                    rules={{ maxLength: 8 }}
                    render={({ field }) => (
                        <FieldWrapper>
                            <Label>California License Number</Label>
                            <Input {...field} />
                            <KendoError>{errors.CaliforniaLicenseNumber?.message}</KendoError>
                        </FieldWrapper>
                    )}
                />
                <Controller
                    name="Email"
                    control={control}
                    rules={{ pattern: { value: EMAIL_REGEX, message: 'Ooops! We need a valid email address.' }, maxLength: 100 }}
                    render={({ field }) => (
                        <FieldWrapper>
                            <Label>Email</Label>
                            <Input {...field} />
                            <KendoError>{errors.Email?.message}</KendoError>
                        </FieldWrapper>
                    )}
                />
                <Controller
                    name="PhoneNumber"
                    control={control}
                    rules={{ required: 'Phone number cannot be empty.', minLength: { value: 9, message: 'Ooops! We need a valid phone number.' } }}
                    render={({ field }) => (
                        <FieldWrapper>
                            <Label>Phone Number</Label>
                            <Input {...field} />
                            <KendoError>{errors.PhoneNumber?.message}</KendoError>
                        </FieldWrapper>
                    )}
                />
                <FieldWrapper>
                    <div className="row">
                        <div className="col-md-6">
                            <Label>Created On</Label>
                            <span className="k-widget">{moment.utc(contractor.CreatedDateTime).local().format('MM/DD/YYYY HH:mm')}</span>
                        </div>
                    </div>
                </FieldWrapper>
                <div className="row">
                    <div className="btn-toolbar justify-content-end my-2">
                        <div className="btn-group">
                            {isAdmin && retireButton}
                            <Button themeColor="primary" className="ms-2" type="submit" disabled={!isDirty}>
                                Update
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ContractorProfile;
