import LoadingPanel from 'components/LoadingPanel';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Title } from 'utils/title';
import {
    Gantt,
    GanttWeekView,
    GanttMonthView,
    GanttDayView,
    GanttYearView,
    filterBy,
    orderBy,
    mapTree,
    extendDataItem,
    GanttTextFilter,
    GanttDateFilter,
    GanttRemoveDialog,
    GanttDataStateChangeEvent,
    GanttExpandChangeEvent,
    GanttTaskClickEvent,
    GanttTaskDoubleClickEvent,
    GanttAddClickEvent,
    GanttTaskRemoveClickEvent,
    GanttRemoveDialogStateChangeEvent,
    GanttRowClickEvent,
} from '@progress/kendo-react-gantt';
import { getter, clone } from '@progress/kendo-react-common';
import { fetchApi } from 'services/api';
import { ConstructionProjectViewModel } from 'TypeGen/construction-project-view-model';
import { KENDO_ADD_DIRECTION_TO_SERVER_DIRECTION, flattenTasks, getTaskIds, transformTask, transformTasks } from 'utils/constructionProject';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AddConstructionProjectTaskDirection } from 'TypeGen/add-construction-project-task-direction';
import { ConstructionProjectTaskViewModel } from 'TypeGen/construction-project-task-view-model';
import { TaskType } from './Task';
import { dateWithoutTimezone } from 'utils/date';
import TaskEditModal from './TaskEditModal';
import { ContractorViewModel } from 'TypeGen/contractor-view-model';
import { PropertyViewModel } from 'utils/property';
import TableView from './TableView';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

type Params = {
    constructionProjectId: string;
};

const taskModelFields = {
    id: 'id',
    start: 'start',
    end: 'end',
    title: 'title',
    contractor: 'contractor',
    percentComplete: 'percentComplete',
    percentGoal: 'percentGoal',
    isRollup: 'isRollup',
    isExpanded: 'isExpanded',
    isInEdit: 'isInEdit',
    children: 'children',
    isSelected: 'isSelected',
    week: 'week',
    drawBudget: 'drawBudget',
};

const getTaskId = getter(taskModelFields.id);

const columns = [
    {
        field: taskModelFields.title,
        title: 'Name',
        width: 200,
        expandable: true,
        filter: GanttTextFilter,
    },
    {
        field: taskModelFields.contractor,
        title: 'Contractor',
        width: 150,
    },
    {
        field: taskModelFields.start,
        title: 'Start',
        width: 120,
        format: '{0:MM/dd/yyyy}',
        filter: GanttDateFilter,
    },
    {
        field: taskModelFields.end,
        title: 'End',
        width: 120,
        format: '{0:MM/dd/yyyy}',
        filter: GanttDateFilter,
    },
];

const Project = () => {
    const { constructionProjectId } = useParams<Params>();
    const { hash } = useLocation();

    const [expandedState, setExpandedState] = useState([7, 11, 12, 13]);
    const [selectedIdState, setSelectedIdState] = useState(null);

    const [editItem, setEditItem] = useState(null);
    const [removeItem, setRemoveItem] = useState(null);

    const [contractors, setContractors] = useState<ContractorViewModel[]>([]);
    const [propertyId, setPropertyId] = useState(-1);
    const [propertyInfo, setPropertyInfo] = useState<PropertyViewModel>();

    const [dataState, setDataState] = useState<any>({
        sort: [],
        filter: [],
    });
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const fetchPropertyInfo = async (propertyId: number) => {
        const res: PropertyViewModel = await fetchApi(`/api/Property/Get/${propertyId}`);
        setPropertyInfo(res);
        return res;
    };

    const { data: taskData, isLoading } = useQuery({
        queryKey: ['taskData', constructionProjectId],
        queryFn: async () => {
            const res: ConstructionProjectViewModel = await fetchApi(`/api/ConstructionProject/Get/${constructionProjectId}`);
            setPropertyId(res.PropertyID);
            if (propertyInfo == null) fetchPropertyInfo(res.PropertyID);
            return transformTasks(res.Tasks);
        },
        // placeholderData: exampleTaskData,
    });

    const add = useMutation({
        mutationFn: async ({ direction, selected }: { selected?: number; direction?: AddConstructionProjectTaskDirection }) => {
            const res: ConstructionProjectTaskViewModel = await fetchApi(
                `/api/ConstructionProject/Task`,
                {
                    ConstructionProjectId: constructionProjectId,
                    Direction: direction,
                    SelectedProjectTaskID: selected,
                },
                'post',
            );
            return transformTask(res);
        },
        onSuccess: () => {
            // recreating server logic is complex here
            // we can just cheat and re-fetch the entire project at a slight latency cost
            queryClient.invalidateQueries({
                queryKey: ['taskData', constructionProjectId],
            });
        },
    });

    const remove = useMutation({
        mutationFn: ({ id }: { id?: number }) =>
            fetchApi(
                `api/ConstructionProject/Task`,
                {
                    ProjectTaskId: id,
                },
                'delete',
            ),
        onSuccess: (_data, variables, _context) => {
            // const newData = taskData?.filter((t) => t.id !== variables.id);
            // queryClient.setQueryData(["taskData", constructionProjectId], newData);
            queryClient.invalidateQueries({
                queryKey: ['taskData', constructionProjectId],
            });
        },
    });

    const update = useMutation({
        mutationFn: ({ id, end, start, percentComplete, percentGoal, title, details, contractorId, drawBudget, week }: TaskType) => {
            const body: Partial<ConstructionProjectTaskViewModel> = {
                PlannedStartDateTime: dateWithoutTimezone(start),
                PlannedEndDateTime: dateWithoutTimezone(end),
                ProgressPercent: percentComplete * 100,
                Name: title,
                ScopeOfWork: details,
                AssignedContractorID: contractorId,
                WeekNumber: week,
                GoalPercent: percentGoal * 100,
                DrawBudget: drawBudget,
            };
            return fetchApi(`api/ConstructionProject/Task/${id}`, body, 'post');
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['taskData', constructionProjectId],
            });
        },
    });

    useEffect(() => {
        (async () => {
            const data: ConstructionProjectViewModel = await fetchApi(`/api/ConstructionProject/Get/${constructionProjectId}`);
            const tasks = transformTasks(data.Tasks);
            // setTaskData(tasks);
            setExpandedState(getTaskIds(tasks));
        })();
    }, [constructionProjectId]);

    useEffect(() => {
        (async () => {
            const data: ContractorViewModel[] = await fetchApi(`/api/Contractor/Get`);
            setContractors(data.filter((c) => c.Active));
        })();
    }, []);

    const onDataStateChange = useCallback((event: GanttDataStateChangeEvent) => setDataState(event.dataState), [setDataState]);

    const onExpandChange = useCallback(
        (event: GanttExpandChangeEvent) => {
            const id = getTaskId(event.dataItem);
            const newExpandedState = event.value ? expandedState.filter((currentId) => currentId !== id) : [...expandedState, id];

            setExpandedState(newExpandedState);
        },
        [expandedState, setExpandedState],
    );

    const onSelectTask = useCallback(
        (event: GanttRowClickEvent) => {
            setSelectedIdState(getTaskId(event.dataItem));
        },
        [setSelectedIdState],
    );

    const onSelectRow = useCallback(
        (event: GanttTaskClickEvent) => {
            setSelectedIdState(getTaskId(event.dataItem));
        },
        [setSelectedIdState],
    );

    const onEdit = useCallback(
        (event: GanttTaskDoubleClickEvent) => {
            setEditItem(clone(event.dataItem));
        },
        [setEditItem],
    );

    const onAdd = useCallback(
        (event: GanttAddClickEvent) => {
            add.mutate({
                direction: KENDO_ADD_DIRECTION_TO_SERVER_DIRECTION[event.direction],
                selected: event?.selectedDataItem?.id,
            });
        },
        [add],
    );

    const onFormSubmit = useCallback(
        (task: TaskType) => {
            update.mutate(task);

            setEditItem(null);
        },
        [update],
    );

    const onFormCancel = useCallback(() => setEditItem(null), [setEditItem]);

    const onRemove = useCallback((event: GanttTaskRemoveClickEvent) => setRemoveItem(event.dataItem), [setRemoveItem]);

    const onRemoveConfirm = useCallback(
        (event: GanttRemoveDialogStateChangeEvent) => {
            remove.mutate({
                id: event.dataItem.id,
            });
            // const newData = removeTask({
            //   removedDataItem: event.dataItem,
            //   taskModelFields: taskModelFields,
            //   dataTree: taskData,
            // });

            setRemoveItem(null);
            // setTaskData(newData);
        },
        [remove],
    );

    const onRemoveCancel = useCallback(() => setRemoveItem(null), [setRemoveItem]);

    const processedData = useMemo(() => {
        const filteredData = filterBy(taskData ?? [], dataState.filter, taskModelFields.children);
        const sortedData = orderBy(filteredData, dataState.sort, taskModelFields.children);

        return mapTree(sortedData, taskModelFields.children, (task: TaskType) =>
            extendDataItem(task, taskModelFields.children, {
                [taskModelFields.isExpanded]: expandedState.includes(getTaskId(task)),
                [taskModelFields.isSelected]: selectedIdState === getTaskId(task),
                [taskModelFields.contractor]: contractors.find((c) => c.ContractorID === task.contractorId)?.FullName ?? null,
            }),
        );
    }, [taskData, dataState.filter, dataState.sort, expandedState, selectedIdState, contractors]);

    const breadCrumb = (propertyInfo == null)
        ? null
        : <Breadcrumb
        >
            <BreadcrumbItem tag={Link} to='/Construction/Projects'>Projects</BreadcrumbItem>
            <BreadcrumbItem tag={Link} to={`/Property/Profile/${propertyId}`} active>{propertyInfo.Address.StreetAddress}</BreadcrumbItem>
        </Breadcrumb>
    ;

    const gantt = (
        <>
            <Gantt
                taskData={processedData}
                taskModelFields={taskModelFields}
                columns={columns}
                reorderable={true}
                sortable={true}
                sort={dataState.sort}
                filter={dataState.filter}
                navigatable={true}
                onExpandChange={onExpandChange}
                onDataStateChange={onDataStateChange}
                toolbar={{ addTaskButton: true }}
                onAddClick={onAdd}
                onTaskClick={onSelectTask}
                onRowClick={onSelectRow}
                onTaskDoubleClick={onEdit}
                onRowDoubleClick={onEdit}
                onTaskRemoveClick={onRemove}
            >
                <GanttWeekView />
                <GanttDayView />
                <GanttMonthView />
                <GanttYearView />
            </Gantt>
            {editItem && (
                <TaskEditModal
                    data={editItem}
                    // onSubmit={onFormSubmit}
                    // onCancel={onFormCancel}
                    onClose={onFormCancel}
                    onSubmit={onFormSubmit}
                    contractors={contractors}
                />
            )}
            {removeItem && <GanttRemoveDialog dataItem={removeItem} onConfirm={onRemoveConfirm} onCancel={onRemoveCancel} onClose={onRemoveCancel} />}
        </>
    );

    const table = taskData && <TableView data={flattenTasks(taskData)} />;
    const menu = <ButtonGroup className='mb-2'>
        <Button
            togglable
            selected={hash === '#calendar'}
            onClick={() => {
                navigate('#calendar');
            }}
        >
            Calendar View
        </Button>
        <Button
            togglable
            selected={hash === '#table' || hash.length === 0}
            onClick={() => {
                navigate('#table');
            }}
        >
            Budget View
        </Button>
    </ButtonGroup>;

    const content = (
        <>
            <div style={hash === '#calendar' ? {} : { width: 0, overflow: 'hidden', height: 0 }}>{gantt}</div>
            <div style={hash === '#table' || hash.length === 0 ? {} : { width: 0, overflow: 'hidden', height: 0 }}>{table}</div>
        </>
    );

    return (
        <div>
            <Title string={propertyInfo?.Address.StreetAddress || 'Project'} />
            {isLoading && <LoadingPanel />}
            {breadCrumb}
            {menu}
            <br />
            {content}
        </div>
    );
};

export default Project;
